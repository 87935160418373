import React from "react";
import ReactDOM from "react-dom/client";
import loadable from "@loadable/component";
import SpinFC from "antd/es/spin";
import ConfigProvider from "antd/es/config-provider";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import FallbackLoader from "./components/FallbackLoader";
import store from "./store/store";

import "./assets/css/style.css";
import "./assets/css/fonts.css";

import Spinner from "./assets/icons/loading_icon.png";
import { Suspense } from "react";

const Layout = loadable(() => import("./layout"), {
  fallback: <FallbackLoader />,
});
const ScrollToTop = loadable(() => import("./components/ScrollToTop/ScrollToTop"));

/**
 * Persist Store Variable
 */
let persistor = persistStore(store);

/**
 * Default root prefix Class for spinner
 */
SpinFC.setDefaultIndicator(
  <div className="viCyber-spinner">
    <img src={Spinner} alt="viCyber-spinner" />
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Space Grotesk',
        screenLGMax: 1299,
        screenXLMin: 1300,
        screenXL: 1300,
      },
      components: {
        Radio: {
          buttonBg: 'rgba(0, 0, 0, 0.1)',
          buttonCheckedBg: 'rgba(255, 255, 255)',
          buttonColor: 'white',
        }
      }
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Suspense fallback={<FallbackLoader />}>
            <ScrollToTop />
            <Layout />
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>
  // </React.StrictMode>
);
